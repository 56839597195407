/*
 * Copyright © Bold Brand Commerce Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */

import extend_fbe26ac6 from '/home/pbk/pim/frontend/modules/@ergonode/attributes/src/config/extends.js';
import extend_fb3b7daa from '/home/pbk/pim/frontend/modules/@ergonode/attribute-groups/src/config/extends.js';
import extend_3ff655a2 from '/home/pbk/pim/frontend/modules/@ergonode/categories/src/config/extends.js';
import extend_41bba6e8 from '/home/pbk/pim/frontend/modules/@ergonode/category-trees/src/config/extends.js';
import extend_9c75c2b6 from '/home/pbk/pim/frontend/modules/@ergonode/core/src/config/extends.js';
import extend_066dcf50 from '/home/pbk/pim/frontend/modules/@ergonode/dashboard/src/config/extends.js';
import extend_6b677596 from '/home/pbk/pim/frontend/modules/@ergonode/channels/src/config/extends.js';
import extend_fc8753c0 from '/home/pbk/pim/frontend/modules/@ergonode/media/src/config/extends.js';
import extend_95b9b5b8 from '/home/pbk/pim/frontend/modules/@ergonode/notifications/src/config/extends.js';
import extend_1573065e from '/home/pbk/pim/frontend/modules/@ergonode/collections/src/config/extends.js';
import extend_37a807ac from '/home/pbk/pim/frontend/modules/@ergonode/products/src/config/extends.js';
import extend_1c517e57 from '/home/pbk/pim/frontend/modules/@ergonode/product-templates/src/config/extends.js';
import extend_1bc7dc39 from '/home/pbk/pim/frontend/modules/@ergonode/roles/src/config/extends.js';
import extend_043afd78 from '/home/pbk/pim/frontend/modules/@ergonode/users/src/config/extends.js';
import extend_44fb98eb from '/home/pbk/pim/frontend/modules/@ergonode/import/src/config/extends.js';
import extend_2b10e7b4 from '/home/pbk/pim/frontend/modules/@ergonode/batch-actions/src/config/extends.js';
import extend_111a958e from '/home/pbk/pim/frontend/modules/@ergonode/products-comments/src/config/extends.js';
import extend_4f18bb48 from '/home/pbk/pim/frontend/modules/@ergonode/product-batch-actions/src/config/extends.js';
import extend_d1560776 from '/home/pbk/pim/frontend/modules/@ergonode/workflow/src/config/extends.js';

export default {
	extend_fbe26ac6,
	extend_fb3b7daa,
	extend_3ff655a2,
	extend_41bba6e8,
	extend_9c75c2b6,
	extend_066dcf50,
	extend_6b677596,
	extend_fc8753c0,
	extend_95b9b5b8,
	extend_1573065e,
	extend_37a807ac,
	extend_1c517e57,
	extend_1bc7dc39,
	extend_043afd78,
	extend_44fb98eb,
	extend_2b10e7b4,
	extend_111a958e,
	extend_4f18bb48,
	extend_d1560776,
};
